import React from 'react'
import {BiLogoYoutube, BiLogoInstagramAlt, BiLogoSnapchat} from 'react-icons/bi'

function SocialLinks() {
  return (
    <div className='social-links'>
        <a href="https://www.instagram.com/leifjansberg/" sizetarget='_blank' rel="noreferrer"><BiLogoInstagramAlt size={50} /></a>
        <a href="https://www.snapchat.com/add/leifberg" target='_blank' rel="noreferrer"><BiLogoSnapchat size={50}/></a>
        <a href="https://www.youtube.com/@themoneymenpodcast" target='_blank' rel="noreferrer"><BiLogoYoutube size={50}/></a>
    </div>
  )
}

export default SocialLinks