import React from 'react'
import SocialLinks from './SocialLinks'

const getWindowSize = () => {
    const {innerWidth} = window;
    return innerWidth
}

function Gallery() {

    const [windowSize, setWindowSize] = React.useState(getWindowSize())

    React.useEffect(() => {
      function handleResize() {
        setWindowSize(getWindowSize())
      }
  
      window.addEventListener('resize', handleResize)
  
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

  return (
    <div className='home-page'>
        {windowSize < 750 && 
        <>
            <div className='hero'>
                <h2>Day trader -  Entrepreneur - Podcast Host</h2>
            </div>
            
        </>}
    
        <div className='gallery'>
            {windowSize > 750 && 
            <>
                <div className='hero'>
                    <h2>Day trader -  Entrepreneur - Podcast Host</h2>
                </div>
                <SocialLinks />
            </>}
            <img className='image_1' require src={require('../assets/images/berlin_wall.JPG')} alt="" />
            <img className='image_2' require src={require('../assets/images/forrest.jpg')} alt="" />
            <img className='image_3' require src={require('../assets/images/gym.jpg')} alt="" />
            <img className='image_5' require src={require('../assets/images/panama.jpg')} alt="" />
            <img className='image_4' require src={require('../assets/images/sky.jpg')} alt="" />
            <img className='image_6' require src={require('../assets/images/steak.JPG')} alt="" />
        </div>
    </div>
  )
}

export default Gallery