import React from 'react'
import { Link } from 'react-router-dom'



export default function SlidingNav({slideNavWidth}) {
  return (
    <div className='sliding-nav' id="sliding-nav" style={{"width": `${slideNavWidth}vw`}}>
        <nav>
                <Link to="/">Home</Link>
                <Link to="/about-me">About Me</Link>
                <Link to='/contact'>Contact</Link>
        </nav>
    </div>
  )
}
