import { Route, Routes } from "react-router";
import AboutMe from "./components/AboutMe";
import Gallery from "./components/Gallery";
import PageContainer from "./components/PageContainer";
import Contact from "./components/Contact";
import Chris from "./components/Chris";
import React from "react";

function App() {
  const [slideNavWidth, setSlideNavWidth] = React.useState(0)

  return (

    <div className="App">
      <Routes>
        <Route exact path="/" element={<PageContainer slideNavWidth={slideNavWidth} setSlideNavWidth={setSlideNavWidth} children={<Gallery />}/>} />
        <Route exact path="/about-me" element={<PageContainer slideNavWidth={slideNavWidth} setSlideNavWidth={setSlideNavWidth} children={<AboutMe />}/>} />
        <Route exact path="/contact" element={<PageContainer slideNavWidth={slideNavWidth} setSlideNavWidth={setSlideNavWidth} children={<Contact />}/>} />
        <Route exact path="/dont-click" element={<PageContainer slideNavWidth={slideNavWidth} setSlideNavWidth={setSlideNavWidth} children={<Chris />}/>} />
      </Routes>
    </div>
  );
}

export default App;
