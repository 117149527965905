import React from 'react'
import { Link } from 'react-router-dom'
import SocialLinks from './SocialLinks'

function Footer() {
  return (
    <footer>
        <div className='--space-between'>
        <SocialLinks />
            <div className='--flex-end'>
                <Link to="/dont-click" className='--text-end'>Don't Click!</Link>
            </div>
        </div>
        <p style={{"fontSize": "18px", "textAlign": "center",}}>Copyright © Aldo Portillo LLC</p>
        
        
    </footer>
  )
}

export default Footer