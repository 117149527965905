import React from 'react'

function AboutMe() {
  return (
    <div className='about-me'>
    <h2>About Me</h2>
        <p>Hey there! I'm Leif Jansberg, and I'm thrilled to welcome you to my corner of the digital world. My journey has been one of constant growth, driven by a passion for self-improvement, exploration, and making a meaningful impact.</p>

        <p>When I'm not absorbed in the fast-paced world of day trading, where I've honed my skills as a self-taught expert, you'll find me embracing a variety of interests. From hitting the gym to fuel my body and mind to venturing into the great outdoors with friends and immersing myself in nature's wonders, I thrive on experiences that push my boundaries.</p>

        <p>My quest for knowledge extends to the realms of literature. Whether I'm diving into a thought-provoking novel or absorbing insights from the latest non-fiction, I'm always seeking fresh perspectives that enrich my understanding of the world.</p>

        <p>But my pursuits aren't limited to just learning and trading. I'm a true believer in the power of values. For me, honor and respect aren't mere words; they're guiding principles that shape my interactions with the world. I'm dedicated to becoming the best version of myself, not only for my own growth but to contribute positively to society and the people who matter to me.</p>

        <p>Beyond the world of numbers and trading charts, I'm a passionate Rolex enthusiast, a thrill-seeker on the snow-covered slopes, and someone who finds inspiration in the discipline of mixed martial arts.</p>

        <p>This platform is a glimpse into my multifaceted life—a blend of financial acumen, personal growth, and an unquenchable thirst for new experiences. Join me as I navigate this ever-evolving journey, striving to leave a mark of excellence and positive change in the lives I touch.</p>

        <p>Thanks for stopping by, and I can't wait to connect with you as we explore life's remarkable adventure together.</p>

        <p>Stay curious, stay inspired.</p>

        <p>Warmly,</p>

        <p>Leif Jansberg</p>
    </div>
  )
}

export default AboutMe