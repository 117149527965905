import React from 'react'

function Chris() {
  return (
    <div className='chris'>
        <h2>Chris</h2>
        <img require src={require('../assets/images/chris.jpg')} alt="" />
    </div>
  )
}

export default Chris