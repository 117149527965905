import React from 'react'
import Header from './Header'
import Footer from './Footer'
import SlidingNav from './SlidingNav'

function PageContainer({children, slideNavWidth, setSlideNavWidth}) {
  return (
    <div className='page-container'>
    <div className='main'>
        <Header slideNavWidth={slideNavWidth} setSlideNavWidth={setSlideNavWidth} />
        {children}
        <Footer />
    </div>
        <SlidingNav slideNavWidth={slideNavWidth} />
    </div>
  )
}

export default PageContainer