import React from 'react'
import { useLocation } from 'react-router-dom'
import { Spin as Hamburger } from 'hamburger-react'
import NavBar from './NavBar';


const getWindowSize = () => {
    const {innerWidth} = window;
    return innerWidth
}

function Header({slideNavWidth, setSlideNavWidth}) {

  const [windowSize, setWindowSize] = React.useState(getWindowSize())
  const location  = useLocation()
  const [openNav, setOpenNav] = React.useState(false)

  React.useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  React.useEffect(() => {
    if(openNav){
        setSlideNavWidth(70)
    } else {
        setSlideNavWidth(0)
    }

  }, [openNav, setSlideNavWidth])
  React.useEffect(() => {
    setOpenNav(false)
    setSlideNavWidth(0)
  },[location, setSlideNavWidth])


  return (
    <header>
        <h1>Leif Jansberg</h1>
        {
            windowSize > 750 ? 
            <NavBar />:
            <div className='hamburger'><Hamburger  hideOutline={false} label="Show menu" direction="left" duration={0.8} color='white' toggled={openNav} toggle={setOpenNav}/></div>
        }

    </header>
  )
}

export default Header

// onToggle={toggled => modifyNav(toggled)}